import React from "react";
import University from "../Menabout/University";
import Header from "../headerone/Main";
import Bredcom from "../Bredcom/Main";
import Scholarship from "../menhome/Scholarship";
import Campus from "../Menabout/Campus";
import FItems from "../menhome/Faqs";

function Main() {
  return (
    <>
      <Header />
      <Bredcom title="Home" subtitle="About Us" />
      <University />
      <Campus />
      <Scholarship />
      <FItems />
    </>
  );
}

export default Main;
