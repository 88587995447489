import React from "react";
import Headerone from "../headerone/Main";
import About from "../menhome/About";
import Courses from "../menhome/Courses";
import Shep from "../menhome/Shep";
import Slider from "../menhome/Sliderone";
import Video from "../menhome/Video";
import Testimonial from "../menhome/Testimonial";
// import Blog from "../menhome/Blog";
import FItems from "../menhome/Faqs";

function Main() {
  return (
    <>
      <Headerone />
      <Slider />
      <About />
      <Courses />
      <Shep />
      <Video />
      <Testimonial />
      <FItems />
      {/* <Blog /> */}
    </>
  );
}

export default Main;
