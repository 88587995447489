import React from "react";
// import { Link } from "react-router-dom";
// import Iframe from "react-iframe";
import img from "../../assets/img/slider/slider_bg.png";

function Video() {
  // const [video, setVideo] = useState(false);

  const getHeadingFontSize = () => {
    if (window.innerWidth <= 576) {
      return "1.5rem";
    } else if (window.innerWidth <= 768) {
      return "1.75rem";
    } else if (window.innerWidth <= 992) {
      return "3rem";
    } else if (window.innerWidth <= 1200) {
      return "3rem";
    } else {
      return "3.5rem";
    }
  };

  const getParagraphFontSize = () => {
    if (window.innerWidth <= 576) {
      return "1rem";
    } else if (window.innerWidth <= 768) {
      return "1.1rem";
    } else if (window.innerWidth <= 992) {
      return "1.125rem";
    } else if (window.innerWidth <= 1200) {
      return "1.125rem";
    } else {
      return "1.125rem";
    }
  };

  return (
    <>
      <section
        className="cta-area cta-bg pt-160 pb-160"
        style={{ backgroundImage: `url(${img})` }}
      >
        <div className="container">
          <div className="row justify-content-center  align-items-center">
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div
                className="section-title cta-title video-title wow fadeInLeft animated"
                data-animation="fadeInDown animated"
                data-delay=".2s"
                style={{ textAlign: "left" }}
              >
                <h2 style={{ fontSize: getHeadingFontSize() }}>
                  Why <span className="ssss">Get Knowledge</span> Academy
                </h2>
                <p style={{ fontSize: getParagraphFontSize() }}>
                  Get knowledge Academy provides a diverse, welcoming, lively
                  and accessible learning environment. We have the latest
                  equipment, along with knowledgeable professional staff and
                  services to help you succeed.
                </p>
              </div>
            </div>
            {/* <div className="col-lg-2 col-md-2"></div> */}
            {/* <div className="col-lg-4">
              <div className="s-video-content">
                <Link
                  to="#"
                  className="popup-video mb-50"
                  onClick={() => setVideo(true)}
                >
                  <img
                    src="assets/img/bg/play-button.png"
                    alt="circle_right"
                    onClick={() => setVideo(true)}
                  />
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      {/* {video && (
        <>
          <div className="mfp-bg mfp-ready"></div>
          <div
            className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready"
            tabIndex="-1"
            style={{ overflow: "hidden" }}
          >
            <div className="mfp-container mfp-s-ready mfp-iframe-holder">
              <div className="mfp-content">
                <div className="mfp-iframe-scaler">
                  <button
                    title="Close (Esc)"
                    type="button"
                    className="mfp-close"
                    onClick={() => setVideo(false)}
                  >
                    ×
                  </button>
                  <Iframe
                    className="mfp-iframe"
                    src="//www.youtube.com/embed/gyGsPlt06bo?autoplay=1"
                    frameBorder="0"
                    allowFullScreen
                  ></Iframe>
                </div>
              </div>
              <div className="mfp-preloader">Loading...</div>
            </div>
          </div>
        </>
      )} */}
    </>
  );
}

export default Video;
