import React from "react";
import img from "../../assets/img/features/about_img_05.png";
import winterImg from "../../assets/img/bg/couress-img-5.jpg";
import springImg from "../../assets/img/bg/couress-img-6.jpg";
import summerImg from "../../assets/img/bg/couress-img-4.jpg";

function University() {
  return (
    <>
      <section
        className="about-area about-p pt-120 pb-120 p-relative fix"
        style={{ background: "#f0f8ff" }}
      >
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div
                className="s-about-img p-relative wow fadeInLeft animated"
                data-animation="fadeInLeft"
                data-delay=".4s"
              >
                <img src={img} alt="img bg about" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div
                className="about-content s-about-content pl-15 wow fadeInRight animated"
                data-animation="fadeInRight"
                data-delay=".4s"
              >
                <div className="about-title second-title pb-25">
                  <h5>
                    <i className="fal fa-graduation-cap" />
                    Welcome to Get knowledge Academy
                  </h5>
                  <h2>Empowering Skills for a Brighter Future</h2>
                </div>
                <p className="txt-clr">
                  Located in the center of Calgary NE, Get knowledge Academy is
                  a premier training institute dedicated to empowering
                  individuals through quality education and practical
                  skill-building. Our programs are quite comprehensive in a
                  range of academic fields to meet the different needs of
                  various career paths and personal growth, with online and
                  offline learning choices.
                </p>
                <p>
                  At Get knowledge Academy, our commitment is to provide an
                  exceptional learning experience that fosters growth,
                  confidence, and career readiness. Our programs are carefully
                  crafted by experienced instructors to equip students with the
                  knowledge and skills needed for success in today's fast-paced
                  world.
                </p>
              </div>
            </div>
          </div>

          {/* Features Section */}
          <div className="about-content2 mt-50">
            <div className="row">
              <div className="col-lg-4 col-md-6 mb-4">
                <div className="feature-box">
                  <img
                    src={winterImg}
                    alt="Winter Wonderland"
                    className="img-fluid"
                    style={{
                      borderRadius: "8px",
                    }}
                  />
                  <h3
                    className="mt-3 feature-title"
                    style={{ color: "#6d8b3f", fontWeight: "bold" }}
                  >
                    Expert-Led Courses
                  </h3>
                  <p className="feature-text text-justify">
                    Courses at the Get knowledge Academy are taught by
                    instructors. with much industry experience and an enthusiasm
                    for teaching. They add practical insights and real-world
                    knowledge to the classroom, ensuring students gain skills
                    that directly apply to their careers. Through interactive
                    through sessions, practical exercises and case studies,
                    students Building confidence and deep understanding of all
                    subjects. Our instructors go beyond traditional teaching,
                    guiding students in a way that bridges theory and real-world
                    application, so they leave with practical skills and
                    knowledge they can immediately put to use.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 mb-4">
                <div className="feature-box">
                  <img
                    src={springImg}
                    alt="Sights of Spring"
                    className="img-fluid"
                    style={{
                      borderRadius: "8px",
                    }}
                  />
                  <h3
                    className="mt-3 feature-title"
                    style={{ color: "#6d8b3f", fontWeight: "bold" }}
                  >
                    Recognized and Valued Certifications
                  </h3>
                  <p className="feature-text  text-justify">
                    It ranges from CPR & First Aid, Alberta. Security Guard
                    Training and CPI Non-Violent Crisis Intervention
                    Intervention Training is known and valued across industries.
                    These credentials not only enhance career prospects, but
                    also validate the practical skills acquired through our
                    programs. Every certification meets or exceeds industry
                    standards, providing students with a solid foundation, and
                    competitive edge in their fields. With the help of Get
                    knowledge Academy’s certifications, students gain confidence
                    and credibility, increasing their value to employers and
                    Readiness for career advancement.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 mb-4">
                <div className="feature-box">
                  <img
                    src={summerImg}
                    alt="Sounds of Summer"
                    className="img-fluid"
                    style={{
                      borderRadius: "8px",
                    }}
                  />
                  <h3
                    className="mt-3 feature-title"
                    style={{ color: "#6d8b3f", fontWeight: "bold" }}
                  >
                    Flexible Learning Options
                  </h3>
                  <p className="feature-text text-justify">
                    In tune with their different needs, we have provided:
                    flexible learning options to suit every person's schedule.
                    Whether you prefer attending classes in a classroom or
                    online. learning, our programs fit your needs. With choices
                    For day-time, evening and weekend classes students may
                    Balance other responsibilities with studies. This
                    Flexibility encourages working professionals, parents and
                    others with busy lives to pursue their educational goals
                    without compromising on quality. At Get Knowledge Academy,
                    We make it easy for the learner to choose a learning path
                    that suits their lifestyle.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Responsive Styles */}
      <style jsx>{`
        .feature-title {
          font-size: 1.5rem;
        }

        .feature-text {
          font-size: 1rem;
        }

        @media (max-width: 1200px) {
          .feature-title {
            font-size: 1.4rem;
          }

          .feature-text {
            font-size: 0.95rem;
          }
        }

        @media (max-width: 992px) {
          .feature-title {
            font-size: 1.3rem;
          }

          .feature-text {
            font-size: 0.9rem;
          }
        }

        @media (max-width: 768px) {
          .feature-title {
            font-size: 1.2rem;
          }

          .feature-text {
            font-size: 0.85rem;
          }
        }

        @media (max-width: 576px) {
          .feature-title {
            font-size: 1.1rem;
          }

          .feature-text {
            font-size: 0.8rem;
          }
        }
      `}</style>
    </>
  );
}

export default University;
