import React from "react";
import Bredcom from "../Bredcom/Main";
import Header from "../headerone/Main";
import Second from "../mencoursedetails/Second";

function Main() {
  return (
    <>
      <Header />
      <Bredcom title="Home" subtitle="Program Details" />
      <Second />
    </>
  );
}

export default Main;
