import React, { useState } from "react";
import img1 from "../../assets/img/bg/couress-img-3.jpg";

const faqItems = [
  {
    id: "headingOne",
    question: "What is the main focus of the CPR & First Aid course?",
    answer:
      "The CPR & First Aid course at Get knowledge Academy focuses on teaching essential life-saving techniques, including CPR, AED usage, and basic first aid skills. It equips participants to handle a wide range of medical emergencies with confidence and efficiency.",
  },
  {
    id: "headingTwo",
    question: "Who is this course ideal for?",
    answer:
      "This course is ideal for individuals looking to prepare for emergencies, as well as professionals in healthcare, childcare, security, and other fields where first aid skills are valuable. It's also beneficial for anyone who wants to be equipped to assist in emergencies in everyday situations.",
  },
  {
    id: "headingThree",
    question: "What certification will I receive upon completion?",
    answer:
      "Upon successful completion, participants earn a certification from Get knowledge Academy, validating their CPR and first aid skills. This certification is essential for careers that require first aid training and serves as proof of your ability to respond to emergencies effectively.",
  },
];

function First() {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      <section className="project-detail">
        <div className="container">
          <div className="lower-content">
            <div className="row">
              <div className="text-column col-lg-9 col-md-9 col-sm-12">
                <h2>CPR and First Aid </h2>
                <div className="upper-box">
                  <div className="single-item-carousel owl-carousel owl-theme">
                    <figure className="image">
                      <img src={img1} alt="" />
                    </figure>
                  </div>
                </div>
                <div className="inner-column">
                  <h3>Program Overview</h3>
                  <p>
                    CPR & First Aid Training Course at Get Knowledge Academy. It
                    is purposed to provide participants with necessary
                    life-saving This includes skills that help individuals react
                    during emergencies. This course involves intense training on
                    the Basic principles of cardiopulmonary resuscitation (CPR)
                    and Basic first aid techniques. Participants will earn the
                    Knowledge and Self-Confidence in Handling Health Problems
                    emergencies in various settings, including workplaces, It
                    works across schools, at home. The program is great for
                    anyone wants to be prepared for every anticipating
                    situation, and is Of great value for those of a healthcare
                    profession, childcare, security, and other professions where
                    first aid Skills are necessary.
                  </p>

                  <h4>Course Content</h4>
                  <p>
                    This CPR & First Aid Training Course is accessible at Get
                    Knowledge Academy. Basic life-skill covers, like CPR.
                    Techniques for Adults, Children, and Infants Using Automated
                    External Defibrillators (AEDs) and dealing airway
                    obstructions. Trainees learn basic first aid to treat
                    Treating wounds, burns, fractures and controlling bleeding
                    Shock, and addressing medical emergencies in the form of
                    Heart attacks, strokes, allergic reactions, and asthma.
                    Attacks. The course also incorporates environmental hazards
                    of heat stroke, hypothermia, and other insect stings, and
                    personal scene safety assessment Use of protective equipment
                    (PPE) First aid kit maintenance. At its peak, graduates
                    receive certification, validating their skills to handle a
                    wide range of emergencies confidently.
                  </p>
                  <ul className="pr-ul">
                    <li>
                      <div className="icon">
                        <i className="fal fa-check" />
                      </div>
                      <div className="text">
                        Master CPR techniques for adults, children, and infants
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check" />
                      </div>
                      <div className="text">
                        Learn to use automated external defibrillators (AEDs).
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check" />
                      </div>
                      <div className="text">
                        Provide first aid for cuts, burns, fractures, and
                        bleeding.
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check" />
                      </div>
                      <div className="text">
                        Conduct scene safety assessments and use PPE.
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check" />
                      </div>
                      <div className="text">
                        Maintain a well-stocked and ready first aid kit.
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check" />
                      </div>
                      <div className="text">
                        Respond to heart attacks, strokes, allergic reactions,
                        and asthma attacks.
                      </div>
                    </li>
                  </ul>
                  <p>
                    By the end of this course, you'll be equipped not only with
                    the knowledge needed to pass your certification but also the
                    practical skills that set you apart in the field.
                  </p>
                  <h3>Frequently Asked Question</h3>
                  <p>
                    A business or organization established to provide a
                    particular service, typically one that involves a organizing
                    transactions.Lorem ipsum is simply free text used by
                    copytyping refreshing. Neque porro est qui dolorem enim var
                    sed efficitur turpis gilla sed sit amet finibus eros. Lorem
                    Ipsum is simply dummy text of the printing..
                  </p>
                  <div
                    className="faq-wrap pt-30 wow fadeInUp animated"
                    data-animation="fadeInUp"
                    data-delay=".4s"
                  >
                    <div className="accordion" id="accordionExample">
                      {faqItems.map((item, index) => (
                        <div className="card" key={index}>
                          <div className="card-header" id={item.id}>
                            <h2 className="mb-0">
                              <button
                                className={`faq-btn${
                                  activeIndex === index ? "" : " collapsed"
                                }`}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${index}`}
                                onClick={() => handleToggle(index)}
                              >
                                {item.question}
                              </button>
                            </h2>
                          </div>
                          <div
                            id={`collapse${index}`}
                            className={`collapse${
                              activeIndex === index ? " show" : ""
                            }`}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="card-body">{item.answer}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <aside className="sidebar-widget info-column">
                  <div className="inner-column3">
                    <h3>Program Features</h3>
                    <ul className="project-info clearfix">
                      <li>
                        <span className="icon fal fa-home-lg-alt" />{" "}
                        Government-Approved Certification Training
                      </li>
                      <li>
                        <span className="icon fal fa-book" />{" "}
                        <strong>Modules:</strong> <span>8-10</span>
                      </li>
                      <li>
                        <span className="icon fal fa-clock" />{" "}
                        <strong>Duration: </strong>
                        <span>1 Day</span>
                      </li>

                      <li>
                        <span className="icon fal fa-globe" />{" "}
                        <strong>Language: </strong> <span>English</span>
                      </li>
                      <li>
                        <div>
                          <h4>Course Highlight</h4>
                          <p className="text-justify">
                            The Get Knowledge Academy CPR & First Aid Training
                            Course offers holistic, hands-on training in basic
                            life-saving skills, including CPR techniques for
                            adults, children, and infants, as well as proper use
                            of Automated External Defibrillators (AEDs). Every
                            participant is indeed ready for real-life
                            emergencies they will likely experience at the
                            workplace or even in daily routines, equipped with
                            proper knowledge and self-confidence to respond
                            accordingly. Graduates receive a government-approved
                            certificate certifying that they can handle various
                            medical emergencies, so this course really is a
                            treasure for anyone who works in the sphere of
                            healthcare, childcare, security, or any profession
                            where first aid is needed.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default First;
