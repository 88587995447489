import React, { useState } from "react";
import img1 from "../../assets/img/bg/couress-img-7.jpg";

const faqItems = [
  {
    id: "headingOne",
    question: "What is the Canada Alberta Job Grant (CAJG)?",
    answer:
      "The Canada Alberta Job Grant (CAJG) is a program that provides funding to employers to support employee Training, helping businesses upskill their workforce effectively.",
  },
  {
    id: "headingTwo",
    question: "Who is eligible for the (CAJG) program?",
    answer:
      "The COJG is available to businesses in Alberta looking to provide Training for their employees. Both employers and prospective employees can benefit from this funding.",
  },
  {
    id: "headingThree",
    question: "How does the (CAJG) benefit businesses?",
    answer:
      "With COJG funding, employers can invest in their employees’ skills, making their workforce more effective and enhancing job satisfaction, productivity, and confidence.",
  },
];

function First() {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      <section className="project-detail">
        <div className="container">
          <div className="lower-content">
            <div className="row">
              <div className="text-column col-lg-9 col-md-9 col-sm-12">
                <h2>Canada Alberta Job Grant Training Program</h2>
                <div className="upper-box">
                  <div className="single-item-carousel owl-carousel owl-theme">
                    <figure className="image">
                      <img src={img1} alt="COJG Training Program" />
                    </figure>
                  </div>
                </div>
                <div className="inner-column">
                  <h3>Program Overview</h3>
                  <p>
                    Employers can upgrade their employees with Alberta funding
                    through training of employees. A Career Get knowledge
                    Academy Under the Alberta Career Get knowledge Academy Act,
                    2005 Get Knowledge Academy is concerned in association with
                    the Canada. Alberta Job Grant. This, launched in 2014, helps
                    Businesses, employees, and the overall workforce.
                  </p>
                  <p>
                    With funding, employers can provide their teams with the
                    Training needed to advance their skills and improve business
                    performance. Get knowledge Academy’s customized Training
                    programs align with your organizational goals and are
                    available when and where you need them.
                  </p>
                  <p>
                    Employers who invest in their workforce can enhance
                    productivity, promote job satisfaction, and open up new
                    growth opportunities for their employees. For more details
                    on applying, eligibility requirements, and restrictions,
                    please see the official Canada Alberta Job Grant page.
                  </p>

                  <h3>Frequently Asked Questions</h3>
                  <div
                    className="faq-wrap pt-30 wow fadeInUp animated"
                    data-animation="fadeInUp"
                    data-delay=".4s"
                  >
                    <div className="accordion" id="accordionExample">
                      {faqItems.map((item, index) => (
                        <div className="card" key={index}>
                          <div className="card-header" id={item.id}>
                            <h2 className="mb-0">
                              <button
                                className={`faq-btn${
                                  activeIndex === index ? "" : " collapsed"
                                }`}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${index}`}
                                onClick={() => handleToggle(index)}
                              >
                                {item.question}
                              </button>
                            </h2>
                          </div>
                          <div
                            id={`collapse${index}`}
                            className={`collapse${
                              activeIndex === index ? " show" : ""
                            }`}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="card-body">{item.answer}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <aside className="sidebar-widget info-column">
                  <div className="inner-column3">
                    <h3>Program Features</h3>
                    <ul className="project-info clearfix">
                      <li>
                        <span className="icon fal fa-home-lg-alt" />{" "}
                        Government-Funded Training Support
                      </li>
                      <li>
                        <span className="icon fal fa-globe" />{" "}
                        <strong>Language:</strong> English
                      </li>
                      <li>
                        <div>
                          <h4>Course Highlight</h4>
                          <p className="text-justify">
                            Get knowledge Academy offers Training tailored to
                            your organization’s needs. Employees gain skills
                            that directly enhance business operations, promoting
                            job satisfaction and growth within your workforce.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default First;
