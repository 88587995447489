import React from "react";
import Bredcom from "../Bredcom/Main";
import Header from "../headerone/Main";
import Sixth from "../mencoursedetails/Sixth";

function Main() {
  return (
    <>
      <Header />
      <Bredcom title="Home" subtitle="Program Details" />
      <Sixth />
    </>
  );
}

export default Main;
