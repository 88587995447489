import React from "react";
import Bredcom from "../Bredcom/Main";
import Header from "../headerone/Main";
import Fifth from "../mencoursedetails/Fifth";

function Main() {
  return (
    <>
      <Header />
      <Bredcom title="Home" subtitle="Program Details" />
      <Fifth />
    </>
  );
}

export default Main;
