import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Footer from "../component/footer/Main";
import About from "../component/About/Main";
import Courses from "../component/Courses/Main";
import Coursesdeatils from "../component/course1/Main";
// import Blog from "../component/Blog/Main";
// import Blogdeatils from "../component/Blogdeatils/Main";
import Contact from "../component/contact/Main";
import Home from "../component/home/Main";
import Main from "../component/course2/Main";
import Course3 from "../component/course3/Main";
import Coruse4 from "../component/course4/Main";
import Coruse5 from "../component/course5/Main";
import Coruse6 from "../component/course6/Main";

function Index() {
  const location = useLocation();
  const path = location.pathname;
  useEffect(() => {
    window.scroll(0, 0);
  }, [path]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/courses" element={<Courses />} />
        <Route
          path="/english-preparation-programs"
          element={<Coursesdeatils />}
        />
        <Route path="/Alberta-Security-Guard-Training" element={<Main />} />
        <Route
          path="/CPI-Non-Violent-Crisis-Intervention-Training"
          element={<Course3 />}
        />
        <Route path="/Academic-Preparation-Program" element={<Coruse4 />} />
        <Route path="/CPR-and-First-Aid" element={<Coruse5 />} />
        <Route
          path="/Canada-Alberta-Job-Grant-Training-Program"
          element={<Coruse6 />}
        />
        {/* <Route path="/blog" element={<Blog />} />
        <Route path="/blog-details" element={<Blogdeatils />} /> 
        hello my name is uzair*/}
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </>
  );
}

export default Index;
