import React from "react";
import img from "../../assets/img/bg/steps-img.png";

function Shep() {
  const features = [
    {
      icon: "assets/img/icon/fea-icon01.png",
      title: "Comprehensive Training",
      description:
        "Our programs provide a holistic approach to learning, combining theory with practical experience to ensure that students gain the skills needed to excel in the workforce.",
    },
    {
      icon: "assets/img/icon/fea-icon02.png",
      title: "Personalized Learning",
      description:
        "We focus on small class sizes and individualized instruction, ensuring that each student receives the attention and guidance necessary for their personal and academic success.",
    },
    {
      icon: "assets/img/icon/fea-icon03.png",
      title: "Global Recognition",
      description:
        "Our certifications and programs are recognized worldwide, opening doors to global opportunities and expanding the career horizons of our students.",
    },
  ];

  return (
    <>
      <section
        className="steps-area p-relative"
        style={{ backgroundColor: "#032e3f", padding: "50px 0" }}
      >
        <div className="container">
          <div className="row align-items-center">
            {/* Text Section */}
            <div className="col-lg-6 col-md-12">
              <div className="section-title mb-35">
                <h2
                  style={{
                    color: "#fff",
                    fontSize: "2rem",
                    marginBottom: "20px",
                  }}
                >
                  Why Our Programs
                </h2>
                <p style={{ color: "#ddd", fontSize: "1.1rem" }}>
                  At our Get knowledge Academy, we believe in empowering
                  students with the knowledge and skills they need to achieve
                  their personal and professional goals.
                </p>
              </div>
              <ul className="pr-20">
                {features.map((feature) => (
                  <li key={feature.icon}>
                    <div className="step-box mb-4">
                      <div className="dnumber">
                        <div className="date-box">
                          <img
                            src={feature.icon}
                            alt="icon"
                            style={{ width: "50px", height: "50px" }}
                          />
                        </div>
                      </div>
                      <div className="text" style={{ marginLeft: "10px" }}>
                        <h3 style={{ color: "#fff", fontSize: "1.5rem" }}>
                          {feature.title}
                        </h3>
                        <p style={{ color: "#ddd", fontSize: "1rem" }}>
                          {feature.description}
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            {/* Image Section */}
            <div className="col-lg-6 col-md-12">
              <div className="step-img">
                <img
                  src={img}
                  alt="learning environment"
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "8px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Responsive adjustments */}
      <style jsx>{`
        @media (max-width: 768px) {
          .section-title h2 {
            font-size: 1.8rem;
            margin-bottom: 15px;
          }
          .section-title p {
            font-size: 1rem;
          }
          .text h3 {
            font-size: 1.25rem;
          }
          .text p {
            font-size: 0.9rem;
          }
        }

        @media (max-width: 576px) {
          .section-title h2 {
            font-size: 1.5rem;
            margin-bottom: 10px;
          }
          .section-title p {
            font-size: 0.9rem;
          }
          .text h3 {
            font-size: 1.1rem;
          }
          .text p {
            font-size: 0.85rem;
          }
        }
      `}</style>
    </>
  );
}

export default Shep;
