import React, { useState } from "react";
import img1 from "../../assets/img/bg/couress-img-5.jpg";

const faqItems = [
  {
    id: "headingOne",
    question:
      "What is the purpose of the Nonviolent Crisis Intervention® training?",
    answer:
      "The Nonviolent Crisis Intervention® training is designed to provide participants with essential skills to prevent and safely manage disruptive or high-risk behaviors. It aims to reduce the risk of injury and enhance workplace safety.",
  },
  {
    id: "headingTwo",
    question: "Who is this course suitable for?",
    answer:
      "This course is suitable for individuals working in environments where there is potential for disruptive behavior, including healthcare, education, security, and other settings that may require de-escalation strategies and crisis management skills.",
  },
  {
    id: "headingThree",
    question: "What skills will I gain from this program?",
    answer:
      "Participants will learn techniques for diffusing conflicts, empathetic communication, stress management, and how to respond safely and effectively to challenging situations, emphasizing safety and care for all parties involved.",
  },
];

function First() {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      <section className="project-detail">
        <div className="container">
          <div className="lower-content">
            <div className="row">
              <div className="text-column col-lg-9 col-md-9 col-sm-12">
                <h2>CPI Non-Violent Crisis Intervention Training</h2>
                <div className="upper-box">
                  <div className="single-item-carousel owl-carousel owl-theme">
                    <figure className="image">
                      <img src={img1} alt="" />
                    </figure>
                  </div>
                </div>
                <div className="inner-column">
                  <h3>Program Overview</h3>
                  <p>
                    Our instructors are trained in Nonviolent Crisis
                    Intervention. Intervention® by Crisis Prevention Institute
                    With a Focus on prevention, this core training program
                    equips staff Students should be provided with effective
                    de-escalation techniques safely. de-escalation of risk
                    behaviours or even disruptive behaviours. It’s been setting
                    the standard for crisis prevention and intervention training
                    for over 35 years. Lower the risk of injury.
                  </p>

                  <h4>Course Content</h4>
                  <p>
                    This course fully trains on Nonviolent Crisis Intervention
                    Techniques Focused on Managing Manage critical situations
                    with confidence and care. Learners They will learn a variety
                    of applied safety skills. Prevention, and good
                    communication. Important topics are those:
                  </p>
                  <ul className="pr-ul">
                    <li>
                      <div className="icon">
                        <i className="fal fa-check" />
                      </div>
                      <div className="text">
                        Comply with legislative mandates.
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check" />
                      </div>
                      <div className="text">
                        Meet regulatory/accreditation standards.
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check" />
                      </div>
                      <div className="text">Improve staff retention.</div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check" />
                      </div>
                      <div className="text">Minimize exposure to liability</div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check" />
                      </div>
                      <div className="text">
                        Promote Care, Welfare, Safety, and SecuritySM.
                      </div>
                    </li>
                  </ul>
                  <p>
                    By the end of this course, you'll be equipped not only with
                    the knowledge needed to pass your certification but also the
                    practical skills that set you apart in the field.
                  </p>
                  <h3>Frequently Asked Question</h3>
                  <p>
                    A business or organization established to provide a
                    particular service, typically one that involves a organizing
                    transactions.Lorem ipsum is simply free text used by
                    copytyping refreshing. Neque porro est qui dolorem enim var
                    sed efficitur turpis gilla sed sit amet finibus eros. Lorem
                    Ipsum is simply dummy text of the printing..
                  </p>
                  <div
                    className="faq-wrap pt-30 wow fadeInUp animated"
                    data-animation="fadeInUp"
                    data-delay=".4s"
                  >
                    <div className="accordion" id="accordionExample">
                      {faqItems.map((item, index) => (
                        <div className="card" key={index}>
                          <div className="card-header" id={item.id}>
                            <h2 className="mb-0">
                              <button
                                className={`faq-btn${
                                  activeIndex === index ? "" : " collapsed"
                                }`}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${index}`}
                                onClick={() => handleToggle(index)}
                              >
                                {item.question}
                              </button>
                            </h2>
                          </div>
                          <div
                            id={`collapse${index}`}
                            className={`collapse${
                              activeIndex === index ? " show" : ""
                            }`}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="card-body">{item.answer}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <aside className="sidebar-widget info-column">
                  <div className="inner-column3">
                    <h3>Program Features</h3>
                    <ul className="project-info clearfix">
                      <li>
                        <span className="icon fal fa-home-lg-alt" />{" "}
                        Government-Approved Certification Training
                      </li>
                      {/* <li>
                        <span className="icon fal fa-book" />{" "}
                        <strong>Modules:</strong> <span>8-10</span>
                      </li> */}
                      <li>
                        <span className="icon fal fa-clock" />{" "}
                        <strong>Duration: </strong>
                        <span>2 days </span>
                      </li>

                      <li>
                        <span className="icon fal fa-globe" />{" "}
                        <strong>Language: </strong> <span>English</span>
                      </li>
                      <li>
                        <div>
                          <h4>Course Highlight</h4>
                          <p className="text-justify">
                            This course provides students with foundational
                            skills in Nonviolent Crisis Intervention, on focus
                            Preventing and Safely Managing Challenging End
                            behaviours. A direct in-situ experience of the
                            Identify risk behaviors, diffuse conflicts, and Use
                            evidence-based de-escalation techniques. Programme
                            Emphasizes empathetic communication, stress
                            Management and professional crisis intervention
                            strategies, ensuring that students are equipped for
                            approaching high-pressure situations with confidence
                            and minimizing risk of injury for anyone involved.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default First;
