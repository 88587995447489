import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Background from "../../assets/img/slider/slider_bg.png";
import Backgroundtwo from "../../assets/img/slider/slider_bg_01.png";
import Backgroundthree from "../../assets/img/slider/sec1.png";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      type="button"
      className={`slick-next ${className}`}
      style={{ ...style }}
      onClick={onClick}
    >
      <i className="far fa-angle-right"></i>
    </button>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      type="button"
      className={`slick-prev ${className}`}
      style={{ ...style }}
      onClick={onClick}
    >
      <i className="far fa-angle-left"></i>
    </button>
  );
}

function Sliderone() {
  const settings = {
    autoplay: true,
    autoplaySpeed: 10000,
    dots: false,
    fade: true,
    arrows: true,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    responsive: [
      { breakpoint: 1200, settings: { dots: false, arrows: false } },
    ],
  };

  return (
    <>
      <style>
        {`
          /* Responsive styling for buttons */
          .slider-btn {
            display: flex;
            gap: 20px; /* Adds spacing between buttons on larger screens */
          }
          
          @media (max-width: 576px) {
            .slider-btn {
              flex-direction: column;
              gap: 10px;
            }
            .slider-btn .btn.ss-btn {
              width: 100%;
              text-align: center;
            }
          }
        `}
      </style>

      <section id="home" className="slider-area fix p-relative">
        <Slider
          className="slider-active"
          style={{ background: "#141b22" }}
          {...settings}
        >
          {/* First Slide */}
          <div>
            <div
              className="single-slider slider-bg"
              style={{
                backgroundImage: `url(${Background})`,
                backgroundSize: "cover",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-7 col-md-7">
                    <div className="slider-content s-slider-content mt-130">
                      <h5 data-animation="fadeInUp" data-delay=".4s">
                        welcome To Get knowledge Academy
                      </h5>
                      <h2 data-animation="fadeInUp" data-delay=".4s">
                        PREPARING PROFESSIONALS
                      </h2>
                      <p data-animation="fadeInUp" data-delay=".6s">
                        FOR THE REAL WORLD. LEARNING, FRIENDSHIP, AND FUN FOR
                        EVERYONE
                      </p>
                      <div className="slider-btn mt-30">
                        <Link
                          to="/about"
                          className="btn ss-btn"
                          data-animation="fadeInLeft"
                          data-delay=".4s"
                        >
                          Discover More{" "}
                          <i className="fal fa-long-arrow-right" />
                        </Link>
                        <Link
                          to="/contact"
                          className="btn ss-btn active"
                          data-animation="fadeInLeft"
                          data-delay=".4s"
                        >
                          Contact Us <i className="fal fa-long-arrow-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-5 p-relative"></div>
                </div>
              </div>
            </div>
          </div>
          {/* Second Slide */}
          <div>
            <div
              className="single-slider slider-bg"
              style={{
                backgroundImage: `url(${Backgroundtwo})`,
                backgroundSize: "cover",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-7 col-md-7">
                    <div className="slider-content s-slider-content mt-130">
                      <h5 data-animation="fadeInUp" data-delay=".4s">
                        Welcome to Get knowledge Academy
                      </h5>
                      <h2 data-animation="fadeInUp" data-delay=".4s">
                        EVER READY FOR YOUR SUCCESS
                      </h2>
                      <p data-animation="fadeInUp" data-delay=".6s">
                        We believe in preparing our students for the future with
                        a balanced approach to learning and growth.
                      </p>
                      <div className="slider-btn mt-30">
                        <Link
                          to="/about"
                          className="btn ss-btn"
                          data-animation="fadeInLeft"
                          data-delay=".4s"
                        >
                          Discover More{" "}
                          <i className="fal fa-long-arrow-right" />
                        </Link>
                        <Link
                          to="/contact"
                          className="btn ss-btn active"
                          data-animation="fadeInLeft"
                          data-delay=".4s"
                        >
                          Contact Us <i className="fal fa-long-arrow-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-5 p-relative"></div>
                </div>
              </div>
            </div>
          </div>
          {/* Third Slide */}
          <div>
            <div
              className="single-slider slider-bg"
              style={{
                backgroundImage: `url(${Backgroundthree})`,
                backgroundSize: "cover",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-7 col-md-7">
                    <div className="slider-content s-slider-content mt-130">
                      <h5 data-animation="fadeInUp" data-delay=".4s">
                        Welcome to Get knowledge Academy
                      </h5>
                      <h2 data-animation="fadeInUp" data-delay=".4s">
                        TRAINING SECURITY PROFESSIONALS
                      </h2>
                      <p data-animation="fadeInUp" data-delay=".6s">
                        Equip yourself with essential security skills, crisis
                        management techniques, and the confidence to handle
                        real-world challenges.
                      </p>
                      <div className="slider-btn mt-30">
                        <Link
                          to="/about"
                          className="btn ss-btn"
                          data-animation="fadeInLeft"
                          data-delay=".4s"
                        >
                          Discover More{" "}
                          <i className="fal fa-long-arrow-right" />
                        </Link>
                        <Link
                          to="/contact"
                          className="btn ss-btn active"
                          data-animation="fadeInLeft"
                          data-delay=".4s"
                        >
                          Contact Us <i className="fal fa-long-arrow-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-5 p-relative"></div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </section>
    </>
  );
}

export default Sliderone;
