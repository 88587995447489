import React from "react";
import Bredcom from "../Bredcom/Main";
import Header from "../headerone/Main";
import Fourth from "../mencoursedetails/Fourth";

function Main() {
  return (
    <>
      <Header />
      <Bredcom title="Home" subtitle="Program Details" />
      <Fourth />
    </>
  );
}

export default Main;
