import React from "react";
import Slider from "react-slick";

function Testimonial() {
  let settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className="testimonial-area pt-120 pb-115 p-relative fix">
        <div className="animations-01">
          <img src="assets/img/bg/an-img-03.png" alt="an-img-01" />
        </div>
        <div className="animations-02">
          <img src="assets/img/bg/an-img-04.png" alt="contact-bg-an-01" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title text-center mb-50 wow fadeInDown animated"
                data-animation="fadeInDown"
                data-delay=".4s"
              >
                <h5>
                  <i className="fal fa-graduation-cap" /> Testimonials
                </h5>
                <h2>What Our Clients Are Saying</h2>
              </div>
            </div>
            <div className="col-lg-12">
              <Slider
                className="testimonial-active wow fadeInUp animated"
                data-animation="fadeInUp"
                data-delay=".4s"
                {...settings}
              >
                <div className="single-testimonial text-center">
                  <div className="qt-img">
                    <img src="assets/img/testimonial/qt-icon.png" alt="quote" />
                  </div>
                  <p>
                    "Get knowledge Academy has been a game changer for my
                    career. The courses were comprehensive, and the instructors
                    were incredibly supportive. I feel much more confident
                    pursuing my professional goals now."
                  </p>
                  <div className="ta-info">
                    <h6>John Doe</h6>
                    <span>Software Engineer</span>
                  </div>
                </div>

                <div className="single-testimonial text-center">
                  <div className="qt-img">
                    <img src="assets/img/testimonial/qt-icon.png" alt="quote" />
                  </div>
                  <p>
                    "The personalized attention and hands-on training made all
                    the difference. I was able to apply everything I learned
                    directly to my job, and it’s already paying off. I highly
                    recommend Get Knowledge Academy."
                  </p>
                  <div className="ta-info">
                    <h6>Jane Smith</h6>
                    <span>Marketing Specialist</span>
                  </div>
                </div>

                <div className="single-testimonial text-center">
                  <div className="qt-img">
                    <img src="assets/img/testimonial/qt-icon.png" alt="quote" />
                  </div>
                  <p>
                    "Thanks to Get knowledge Academy, I landed my dream job. The
                    curriculum was relevant and practical, and the networking
                    opportunities were invaluable."
                  </p>
                  <div className="ta-info">
                    <h6>David Williams</h6>
                    <span>Project Manager</span>
                  </div>
                </div>

                <div className="single-testimonial text-center">
                  <div className="qt-img">
                    <img src="assets/img/testimonial/qt-icon.png" alt="quote" />
                  </div>
                  <p>
                    "I was initially hesitant about online courses, but Get
                    knowledge Academy exceeded my expectations. The flexibility
                    and quality of instruction made it easy for me to balance my
                    studies with work."
                  </p>
                  <div className="ta-info">
                    <h6>Samantha Brown</h6>
                    <span>Business Analyst</span>
                  </div>
                </div>

                <div className="single-testimonial text-center">
                  <div className="qt-img">
                    <img src="assets/img/testimonial/qt-icon.png" alt="quote" />
                  </div>
                  <p>
                    "Get knowledge Academy truly cares about its students'
                    success. The mentors and instructors were always available
                    to offer guidance, and I left feeling equipped with the
                    tools I need for the future."
                  </p>
                  <div className="ta-info">
                    <h6>Michael Lee</h6>
                    <span>Data Scientist</span>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Testimonial;
