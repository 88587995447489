import React, { useState } from "react";
import img1 from "../../assets/img/bg/couress-img-1.jpg";

const faqItems = [
  {
    id: "headingOne",
    question: "What is the purpose of the English Preparation Program?",
    answer:
      "The English Preparation Program is designed to help students enhance their language skills, equipping them for success in diploma programs and professional environments.",
  },
  {
    id: "headingTwo",
    question: "What language skills will I develop in this program?",
    answer:
      "Students will focus on improving their skills in listening, speaking, reading, and writing, with an emphasis on formal and colloquial English usage for the workplace.",
  },
  {
    id: "headingThree",
    question: "How does this program prepare me for career success?",
    answer:
      "The English Preparation Program curriculum includes practical skills training in effective communication, public speaking, business norms, and essential professional interactions, making you stand out in your career field.",
  },
];

function First() {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      <section className="project-detail">
        <div className="container">
          <div className="lower-content">
            <div className="row">
              <div className="text-column col-lg-9 col-md-9 col-sm-12">
                <h2>English Preparation Program</h2>
                <div className="upper-box">
                  <div className="single-item-carousel owl-carousel owl-theme">
                    <figure className="image">
                      <img src={img1} alt="" />
                    </figure>
                  </div>
                </div>
                <div className="inner-column">
                  <h3>Program Overview</h3>
                  <p>
                    Students who continue and improve in the English language
                    The Technically, students well-skilled even before studies
                    could sustain themselves. quite well In the professional
                    diploma programmes followed at Acquire, knowledge Academy.
                    That is the reason we developed the English Preparation
                    Program, which complies with the requirements for Learning
                    Support For the Emergent Learner of English.
                  </p>

                  <h4>Course Content</h4>
                  <p>
                    Students would be taught the required English language
                    Needed skills for personal career development. Course topics
                    shall include formal writing, proper grammar structure,
                    expose to North American business standards and in
                    colloquial and Informal discussion. Students will be
                    actively engaged in learning environment conceived to offer
                    prompt feedback and more work.
                  </p>
                  <ul className="pr-ul">
                    <li>
                      <div className="icon">
                        <i className="fal fa-check" />
                      </div>
                      <div className="text">
                        Advanced Grammar and Vocabulary Usage
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check" />
                      </div>
                      <div className="text">
                        Effective Written and Verbal Communication
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check" />
                      </div>
                      <div className="text">
                        Mastery of Colloquial and Formal Language
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check" />
                      </div>
                      <div className="text">
                        Developing Confidence in Public Speaking
                      </div>
                    </li>
                  </ul>
                  <p>
                    By the end of this course, you'll be equipped not only with
                    the knowledge needed to pass your certification but also the
                    practical skills that set you apart in the field.
                  </p>
                  <h3>Frequently Asked Question</h3>
                  <p>
                    A business or organization established to provide a
                    particular service, typically one that involves a organizing
                    transactions.Lorem ipsum is simply free text used by
                    copytyping refreshing. Neque porro est qui dolorem enim var
                    sed efficitur turpis gilla sed sit amet finibus eros. Lorem
                    Ipsum is simply dummy text of the printing.
                  </p>
                  <div
                    className="faq-wrap pt-30 wow fadeInUp animated"
                    data-animation="fadeInUp"
                    data-delay=".4s"
                  >
                    <div className="accordion" id="accordionExample">
                      {faqItems.map((item, index) => (
                        <div className="card" key={index}>
                          <div className="card-header" id={item.id}>
                            <h2 className="mb-0">
                              <button
                                className={`faq-btn${
                                  activeIndex === index ? "" : " collapsed"
                                }`}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${index}`}
                                onClick={() => handleToggle(index)}
                              >
                                {item.question}
                              </button>
                            </h2>
                          </div>
                          <div
                            id={`collapse${index}`}
                            className={`collapse${
                              activeIndex === index ? " show" : ""
                            }`}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="card-body">{item.answer}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <aside className="sidebar-widget info-column">
                  <div className="inner-column3">
                    <h3>Program Features</h3>
                    <ul className="project-info clearfix">
                      <li>
                        <span className="icon fal fa-home-lg-alt" />{" "}
                        Government-Approved Certification Training
                      </li>
                      <li>
                        <span className="icon fal fa-book" />{" "}
                        <strong>Modules:</strong> <span>8-10</span>
                      </li>
                      <li>
                        <span className="icon fal fa-clock" />{" "}
                        <strong>Duration: </strong>
                        <span>12 Weeks</span>
                      </li>

                      <li>
                        <span className="icon fal fa-globe" />{" "}
                        <strong>Language: </strong> <span>English</span>
                      </li>
                      <li>
                        <div>
                          <h4>Course Highlight</h4>
                          <p className="text-justify">
                            Within this course, the learner will experience
                            firsthand Skills required in the workplace: comprise
                            Listening and recording vigilantly. Information
                            Provided through phone calls and meetings; typing
                            and consulting police for help, readings and
                            official documents as well as newspaper Articles and
                            speech in fluency pairs to report Provide relevant
                            information.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default First;
