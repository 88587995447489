import React from "react";
import img1 from "../../assets/img/bg/steps-img-2.png";
import img2 from "../../assets/img/bg/steps-img-3.png";

function Campus() {
  return (
    <>
      <section
        className="steps-area2 p-relative fix"
        style={{ backgroundColor: "#125875" }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div
                className="step-box step-box2 wow fadeInUp animated"
                data-animation="fadeInUp"
                data-delay=".4s"
              >
                <div className="dnumber">
                  <div className="date-box">
                    <img src="assets/img/icon/fea-icon01.png" alt="icon" />
                  </div>
                </div>
                <div className="text">
                  <h2>Explore Get Knowledge</h2>
                  <p>
                    Have a campus tour at Get knowledge Academy to see the
                    diverse opportunities we offer. You’ll see our modern
                    facilities, experience the robust student life and learn how
                    knowledge academy can help shape your future. Meet with our
                    admissions representatives to get personalized guidance and
                    which one is most in line with your career goals.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div
                className="step-img2 wow fadeInLeft animated"
                data-animation="fadeInLeft"
                data-delay=".4s"
              >
                <img src={img1} alt="class" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="steps-area2 p-relative fix"
        style={{ backgroundColor: "#032e3f" }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div
                className="step-img3 wow fadeInLeft animated"
                data-animation="fadeInLeft"
                data-delay=".4s"
              >
                <img src={img2} alt="class" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div
                className="step-box step-box3 wow fadeInUp animated"
                data-animation="fadeInUp"
                data-delay=".4s"
              >
                <div className="dnumber">
                  <div className="date-box">
                    <img src="assets/img/icon/fea-icon03.png" alt="icon" />
                  </div>
                </div>
                <div className="text">
                  <h2>Life at Get Knowledge</h2>
                  <p>
                    Campus life at Get knowledge Academy offers rich and A
                    diverse community that brought learning beyond classrooms.
                    Our students thrive in an environment that promotes
                    collaboration, creativity, personal growth. Whether you are
                    joining student clubs, workshop. or exploring out-of-campus
                    activities, there is always something to get up to.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <style jsx>{`
        /* Styling for the date-box and responsiveness */
        .date-box {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          background-color: #2193c5;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .date-box img {
          max-width: 70%;
          max-height: 70%;
          display: block;
        }

        .text h2 {
          font-size: 2rem;
        }

        .text p {
          font-size: 1rem;
        }

        /* For larger screens */
        @media (min-width: 1200px) {
          .text h2 {
            font-size: 2.5rem;
          }

          .text p {
            font-size: 1.125rem;
          }

          .date-box {
            width: 100px;
            height: 100px;
          }

          .date-box img {
            max-width: 70%;
            max-height: 70%;
          }
        }

        /* For tablet screens */
        @media (min-width: 768px) and (max-width: 1199px) {
          .text h2 {
            font-size: 2rem;
          }

          .text p {
            font-size: 1rem;
          }

          .date-box {
            width: 80px;
            height: 80px;
          }

          .date-box img {
            max-width: 70%;
            max-height: 70%;
          }
        }

        /* For smaller screens */
        @media (max-width: 767px) {
          .text h2 {
            font-size: 1.75rem;
          }

          .text p {
            font-size: 0.875rem;
          }

          .date-box {
            width: 60px;
            height: 60px;
          }

          .date-box img {
            max-width: 70%;
            max-height: 70%;
          }
        }
      `}</style>
    </>
  );
}

export default Campus;
