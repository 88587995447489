import React, { useState } from "react";
import img1 from "../../assets/img/bg/couress-img-2.jpg";

const faqItems = [
  {
    id: "headingOne",
    question: "What does the Alberta Security Guard Training cover?",
    answer:
      "Our training program covers essential security skills including legal responsibilities, emergency response, patrol techniques, and crisis management. It also includes effective communication and conflict resolution skills necessary for a security role.",
  },
  {
    id: "headingTwo",
    question: "Is the course government-approved?",
    answer:
      "Yes, this program is government-approved and designed to meet Alberta’s security licensing requirements. It provides a comprehensive foundation for passing the provincial security exam and beginning a career in security.",
  },
  {
    id: "headingThree",
    question: "How long is the training, and what certification do I receive?",
    answer:
      "The training is completed over 2 weeks, totaling 40 hours. Upon successful completion, participants receive a certification, which is essential for obtaining a provincial security license in Alberta.",
  },
];

function First() {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      <section className="project-detail">
        <div className="container">
          <div className="lower-content">
            <div className="row">
              <div className="text-column col-lg-9 col-md-9 col-sm-12">
                <h2>Alberta Security Guard Training</h2>
                <div className="upper-box">
                  <div className="single-item-carousel owl-carousel owl-theme">
                    <figure className="image">
                      <img src={img1} alt="" />
                    </figure>
                  </div>
                </div>
                <div className="inner-column">
                  <h3>Program Overview</h3>
                  <p>
                    At Get Knowledge Academy for Guard Training, we are
                    Dedicated to helping you succeed at each stage of your
                    security career. Our resources go beyond exam preparation,
                    Trying to make you standout before your employers and become
                    successful in your job. role. This Government accredited
                    training It includes everything needed in your provincial
                    program. security examination. You'll go over core safety
                    concepts and gain advanced skills in patrol techniques,
                    managing Difficult situations, and effective application of
                    crisis Management practices. Through our training, you are
                    making your certification and develop the professional
                    skills required to They thrive in this security field.
                  </p>

                  <h4>Course Content</h4>
                  <p>
                    Our Alberta Security Guard Training program covers much.
                    varied sets of topics to have a comprehensive basis for your
                    Role in security. These mainly include; Legal
                    Responsibilities and Authority Basic Security Patrol
                    Techniques of Emergency Response and Crisis Management
                    Effective Communication and Report Writing Managing
                    Difficult situation and Conflict resolution Employment of
                    force and Defense Strategy by the end of these lessons.
                    Equipped not only with the knowledge required to pass your
                    certification but also the applied skills that mark you
                    apart in the field.
                  </p>
                  <ul className="pr-ul">
                    <li>
                      <div className="icon">
                        <i className="fal fa-check" />
                      </div>
                      <div className="text">
                        Legal Responsibilities and Authority
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check" />
                      </div>
                      <div className="text">
                        Basic Security Patrol Techniques
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check" />
                      </div>
                      <div className="text">
                        Emergency Response and Crisis Management
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check" />
                      </div>
                      <div className="text">
                        Effective Communication and Report Writing
                      </div>
                    </li>

                    <li>
                      <div className="icon">
                        <i className="fal fa-check" />
                      </div>
                      <div className="text">
                        Managing Difficult Situations and Conflict Resolution
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check" />
                      </div>
                      <div className="text">
                        Use of Force and Defensive Tactics
                      </div>
                    </li>
                  </ul>
                  <p>
                    By the end of this course, you'll be equipped not only with
                    the knowledge needed to pass your certification but also the
                    practical skills that set you apart in the field.
                  </p>
                  <h3>Frequently Asked Question</h3>
                  <p>
                    A business or organization established to provide a
                    particular service, typically one that involves a organizing
                    transactions.Lorem ipsum is simply free text used by
                    copytyping refreshing. Neque porro est qui dolorem enim var
                    sed efficitur turpis gilla sed sit amet finibus eros. Lorem
                    Ipsum is simply dummy text of the printing..
                  </p>
                  <div
                    className="faq-wrap pt-30 wow fadeInUp animated"
                    data-animation="fadeInUp"
                    data-delay=".4s"
                  >
                    <div className="accordion" id="accordionExample">
                      {faqItems.map((item, index) => (
                        <div className="card" key={index}>
                          <div className="card-header" id={item.id}>
                            <h2 className="mb-0">
                              <button
                                className={`faq-btn${
                                  activeIndex === index ? "" : " collapsed"
                                }`}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${index}`}
                                onClick={() => handleToggle(index)}
                              >
                                {item.question}
                              </button>
                            </h2>
                          </div>
                          <div
                            id={`collapse${index}`}
                            className={`collapse${
                              activeIndex === index ? " show" : ""
                            }`}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="card-body">{item.answer}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <aside className="sidebar-widget info-column">
                  <div className="inner-column3">
                    <h3>Program Features</h3>
                    <ul className="project-info clearfix">
                      <li>
                        <span className="icon fal fa-home-lg-alt" />{" "}
                        Government-Approved Certification Training
                      </li>
                      <li>
                        <span className="icon fal fa-book" />{" "}
                        <strong>Modules:</strong> <span>8-10</span>
                      </li>
                      <li>
                        <span className="icon fal fa-clock" />{" "}
                        <strong>Duration: </strong>
                        <span>2 Weeks </span>
                      </li>

                      <li>
                        <span className="icon fal fa-globe" />{" "}
                        <strong>Language: </strong> <span>English</span>
                      </li>
                      <li>
                        <div>
                          <h4>Course Highlight</h4>
                          <p className="text-justify">
                            Our government approved security training includes
                            essential skills like security basics, advanced
                            patrol, crisis response, and dealing with
                            high-pressure Career support includes resume
                            building, interview tips, job search counseling,
                            on-the-job Safety, client communication skills,
                            preparing for you for a successful security career.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default First;
