import React from "react";
import { Link } from "react-router-dom";
import img from "../../assets/img/features/about_img_02.png";
import img1 from "../../assets/img/about/num1.png";
import img2 from "../../assets/img/about/num2.png";

function About() {
  return (
    <>
      <section
        className="about-area about-p pt-120 pb-120 p-relative fix"
        style={{ background: "#eff7ff" }}
      >
        <div className="animations-02">
          <img src="assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
        </div>

        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div
                className="s-about-img p-relative  wow fadeInLeft animated"
                data-animation="fadeInLeft"
                data-delay=".4s"
              >
                <img src={img} alt="img bg about" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div
                className="about-content s-about-content pl-15 wow fadeInRight  animated"
                data-animation="fadeInRight"
                data-delay=".4s"
              >
                <div className="about-title second-title pb-25">
                  <h5>
                    <i className="fal fa-graduation-cap" /> About Get knowledge
                    Academy
                  </h5>
                  <h2>A Few Words About Our Get knowledge Academy</h2>
                </div>
                <p className="txt-clr">
                  Get knowledge Academy is committed to shaping the future of
                  education through dynamic learning experiences. We offer a
                  wide range of programs that empower students to excel in their
                  personal and professional lives.
                </p>
                <p>
                  Our programs include English Preparation, Corporate Training,
                  and many more, designed to meet the needs of diverse learners.
                  We are proud to be a hub of academic excellence, offering
                  skills and knowledge that open doors to new opportunities.
                </p>
                <div className="about-content2">
                  <div className="row">
                    <div className="col-md-12">
                      <ul className="green2">
                        <li>
                          <div className="abcontent">
                            <div className="ano">
                              <img src={img1} alt="" />
                            </div>
                            <div className="text">
                              <h3>Alberta Security Guard Training</h3>
                              <p>
                                Comprehensive training to excel in Alberta’s
                                security industry.
                              </p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="abcontent">
                            <div className="ano">
                              <img src={img2} alt="" />
                            </div>
                            <div className="text">
                              <h3>CPR & First Aid Training</h3>
                              <p>
                                Essential lifesaving skills and certifications
                                to ensure you're prepared for emergencies.
                              </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="slider-btn mt-20">
                  <Link to="/about" className="btn ss-btn smoth-scroll">
                    Learn More <i className="fal fa-long-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
