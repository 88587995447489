import React, { useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "emailjs-com";

function Form() {
  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send email using Email.js
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID, // Service ID
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID1, // Template ID
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY // Public Key
      )
      .then(
        (result) => {
          toast.success("Message sent!"); // Success message
          form.current.reset(); // Reset form fields after successful send
        },
        (error) => {
          toast.error("Failed to send message. Try again."); // Error message
        }
      );
  };

  return (
    <>
      <section
        id="contact"
        className="contact-area after-none contact-bg pt-120 pb-120 p-relative fix"
        style={{ background: "#e7f0f8" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 order-2">
              <div className="contact-bg">
                <div className="section-title center-align text-center mb-50">
                  <h2>Contact Us</h2>
                </div>
                <form
                  ref={form}
                  onSubmit={handleSubmit} // Calls handleSubmit on form submission
                  className="contact-form mt-30 text-center"
                >
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="contact-field p-relative c-name mb-30">
                        <input
                          type="text"
                          id="firstn"
                          name="from_name" // Change to "from_name" to match template variable
                          placeholder="First Name"
                          required
                        />
                        <i className="icon fal fa-user" />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="contact-field p-relative c-subject mb-30">
                        <input
                          type="email"
                          id="email"
                          name="from_email" // Change to "from_email" to match template variable
                          placeholder="Email"
                          required
                        />
                        <i className="icon fal fa-envelope" />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="contact-field p-relative c-subject mb-30">
                        <input
                          type="tel"
                          id="phone"
                          name="phone"
                          placeholder="Phone No."
                          required
                        />
                        <i className="icon fal fa-phone" />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="contact-field p-relative c-message mb-30">
                        <textarea
                          name="message"
                          id="message"
                          cols={30}
                          rows={5}
                          placeholder="Write comments"
                          required
                        />
                        <i className="icon fal fa-edit" />
                      </div>
                      <div className="slider-btn text-center">
                        <button
                          type="submit" // Specifies that this button submits the form
                          className="btn ss-btn"
                          data-animation="fadeInRight"
                          data-delay=".8s"
                        >
                          Make A Request{" "}
                          <i className="fal fa-long-arrow-right" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ToastContainer for notifications */}
      <ToastContainer position="top-center" autoClose={1000} />
    </>
  );
}

export default Form;
