import React, { useState } from "react";
import img1 from "../../assets/img/bg/couress-img-1.jpg";

const faqItems = [
  {
    id: "headingThree",
    question: "01 What is the main goal of the training?",
    answer:
      "Our training program is designed to reimagine and transform learning. We aim to provide a more relevant, practical, and impactful education experience, supported by our experienced instructors and comprehensive resources.",
  },
  {
    id: "headingOne",
    question: "02 How does the training stand out?",
    answer:
      "Our training combines expertise from various fields, making it uniquely positioned to bridge academic knowledge with practical skills. This approach ensures that learners gain not just theoretical insights but real-world, applicable skills.",
  },
  {
    id: "headingTwo",
    question: "03 What can participants expect to gain?",
    answer:
      "Participants can expect an enriched learning experience that equips them with the tools and knowledge needed for their professional growth. The training fosters both personal and career development, enabling participants to succeed in their roles with confidence.",
  },
];

function First() {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      <section className="project-detail">
        <div className="container">
          <div className="lower-content">
            <div className="row">
              <div className="text-column col-lg-9 col-md-9 col-sm-12">
                <h2>Academic Preparation Program</h2>
                <div className="upper-box">
                  <div className="single-item-carousel owl-carousel owl-theme">
                    <figure className="image">
                      <img src={img1} alt="" />
                    </figure>
                  </div>
                </div>
                <div className="inner-column">
                  <h3>Program Overview</h3>
                  <p>
                    Get knowledge Academy APC: The Academic Preparation Course
                    It prepares the student for the completion of his diploma.
                    Programme, Academically, and to develop an understanding of
                    North America business culture. This enables the student
                    these prepare themselves for the academic pursuit of content
                    To be ready for jobs and for the workplace.
                  </p>

                  <h4>Course Content</h4>
                  <p>
                    The program academically instructs the students to: Skills
                    include: listening, speaking, reading, and writing. Students
                    will acquire core numerical skills Applied to the business.
                    Subjects in this course focus on the Imp. of punctuality and
                    attendance at the workplace as well as topics under the
                    headings of; resume, Relationship Building, and Career
                    Success.
                  </p>
                  <ul className="pr-ul">
                    <li>
                      <div className="icon">
                        <i className="fal fa-check" />
                      </div>
                      <div className="text">Refine core language skills.</div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check" />
                      </div>
                      <div className="text">
                        Gain essential business math skills.
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check" />
                      </div>
                      <div className="text">
                        Highlight workplace punctuality.
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check" />
                      </div>
                      <div className="text">Create an impactful resume.</div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check" />
                      </div>
                      <div className="text">Build effective relationships.</div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check" />
                      </div>
                      <div className="text">Prepare for career success.</div>
                    </li>
                  </ul>
                  <p>
                    By the end of this course, you'll be equipped not only with
                    the knowledge needed to pass your certification but also the
                    practical skills that set you apart in the field.
                  </p>
                  <h3>Frequently Asked Question</h3>
                  <p>
                    A business or organization established to provide a
                    particular service, typically one that involves a organizing
                    transactions.Lorem ipsum is simply free text used by
                    copytyping refreshing. Neque porro est qui dolorem enim var
                    sed efficitur turpis gilla sed sit amet finibus eros. Lorem
                    Ipsum is simply dummy text of the printing..
                  </p>
                  <div
                    className="faq-wrap pt-30 wow fadeInUp animated"
                    data-animation="fadeInUp"
                    data-delay=".4s"
                  >
                    <div className="accordion" id="accordionExample">
                      {faqItems.map((item, index) => (
                        <div className="card" key={index}>
                          <div className="card-header" id={item.id}>
                            <h2 className="mb-0">
                              <button
                                className={`faq-btn${
                                  activeIndex === index ? "" : " collapsed"
                                }`}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${index}`}
                                onClick={() => handleToggle(index)}
                              >
                                {item.question}
                              </button>
                            </h2>
                          </div>
                          <div
                            id={`collapse${index}`}
                            className={`collapse${
                              activeIndex === index ? " show" : ""
                            }`}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="card-body">{item.answer}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <aside className="sidebar-widget info-column">
                  <div className="inner-column3">
                    <h3>Program Features</h3>
                    <ul className="project-info clearfix">
                      <li>
                        <span className="icon fal fa-home-lg-alt" />{" "}
                        Government-Approved Certification Training
                      </li>
                      <li>
                        <span className="icon fal fa-book" />{" "}
                        <strong>Modules:</strong> <span>8-10</span>
                      </li>
                      <li>
                        <span className="icon fal fa-clock" />{" "}
                        <strong>Duration: </strong>
                        <span>4 weeks</span>
                      </li>

                      <li>
                        <span className="icon fal fa-globe" />{" "}
                        <strong>Language: </strong> <span>English</span>
                      </li>
                      <li>
                        <div>
                          <h4>Course Highlight</h4>
                          <p className="text-justify">
                            Academic Preparation by the Get knowledge Academy
                            Course (APC) Prepare students for success in diploma
                            Training in focused programs and career prospects. 
                            in language skills, basic business numeracy, and
                            Understanding work culture in North America. Topics
                            cover workplace punctuality, resume-building,
                            relationship skills, and career preparation, for a
                            student is equipped to perform well academically
                            with professionally.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default First;
