import React from "react";
import { Link } from "react-router-dom";
import img1 from "../../assets/img/bg/couress-img-1.jpg";
import img2 from "../../assets/img/bg/couress-img-2.jpg";
import img3 from "../../assets/img/bg/couress-img-5.jpg";
import img4 from "../../assets/img/bg/couress-img-4.jpg";
import img5 from "../../assets/img/bg/couress-img-3.jpg";
import img6 from "../../assets/img/bg/couress-img-7.jpg";

function First() {
  const wordLimit = 20; // Limit the number of words in the description

  const truncateDescription = (description, limit) => {
    const words = description.split(" ");
    if (words.length > limit) {
      return words.slice(0, limit).join(" ") + " ...";
    }
    return description;
  };

  const courses = [
    {
      imgSrc: img1,
      category: " ",
      title: "English Preparation Program",
      description:
        "Students who advance and improve their English language skills prior to commencing their studies are able to thrive in the professional diploma programs offered at Get knowledge Academy.",
      link: "/english-preparation-programs",
    },
    {
      imgSrc: img2,
      category: "",
      title: "Alberta Security Guard Training",
      description:
        "At Get Knowledge Academy for Guard Training, we are dedicated to helping you succeed at every stage of your security career.",
      link: "/Alberta-Security-Guard-Training",
    },
    {
      imgSrc: img3,
      category: " ",
      title: "CPI Non-Violent Crisis Intervention Training",
      description:
        "Our instructors are trained in Nonviolent Crisis Intervention® by the Crisis Prevention Institute",
      link: "/CPI-Non-Violent-Crisis-Intervention-Training",
    },
    {
      imgSrc: img4,
      category: "",
      title: "Academic Preparation Course",
      description:
        "The Get knowledge Academy Academic Preparation Course (APC) aims to prepare the student for success in their diploma program, academically and to develop an understanding of business culture in North America.",
      link: "/Academic-Preparation-Program",
    },
    {
      imgSrc: img5,
      category: "",
      title: "CPR and First Aid Training",
      description:
        "The CPR & First Aid Training Course at Get knowledge Academy is designed to equip participants with essential life-saving skills",
      link: "/CPR-and-First-Aid",
    },
    {
      imgSrc: img6,
      category: "",
      title: "Corporate Jobs Training",
      description:
        "Employers can upskill their workforce with Ontario funding through employee Training  As a career Get knowledge Academy under the Alberta Career Get knowledge Academy Act, 2005, Get Knowledge Academy is proud to work with the Canada Alberta Job Grant",
      link: "/Canada-Alberta-Job-Grant-Training-Program",
    },
  ];

  return (
    <>
      <section
        className="shop-area pt-120 pb-120 p-relative "
        data-animation="fadeInUp animated"
        data-delay=".2s"
      >
        <div className="container">
          <div className="row align-items-center">
            {courses.map((course, index) => (
              <div className="col-lg-4 col-md-6" key={index}>
                <div className="courses-item mb-30 hover-zoomin">
                  <div className="thumb fix">
                    <Link to={course.link}>
                      <img src={course.imgSrc} alt="course-img" />
                    </Link>
                  </div>
                  <div className="courses-content">
                    <div className="cat">
                      <i className="fal fa-graduation-cap" /> {course.category}
                    </div>
                    <h3>
                      <Link to={course.link}>{course.title}</Link>
                    </h3>
                    <p>{truncateDescription(course.description, wordLimit)}</p>
                    <Link to={course.link} className="readmore">
                      Read More <i className="fal fa-long-arrow-right" />
                    </Link>
                  </div>
                  <div className="icon">
                    <img src="assets/img/icon/cou-icon.png" alt="icon" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default First;
