import React, { useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "emailjs-com";

const faqItems = [
  {
    question: "What programs does Get knowledge Academy offer?",
    answer:
      "Get knowledge Academy offers a variety of programs, including English Preparation, Corporate Training, and other specialized courses aimed at career development and skill enhancement.",
  },
  {
    question: "What are the fees for the courses?",
    answer:
      "Course fees vary depending on the program. For detailed information on tuition and fees, please refer to the course pages or contact our admissions office.",
  },
  {
    question: "Do you offer online courses?",
    answer:
      "Yes, Get knowledge Academy offers both on-campus and online courses to provide flexibility for students. Check our course catalog to see which programs are available online.",
  },
  {
    question: "What support services are available for students?",
    answer:
      "We provide a range of support services including academic advising, career counseling, and technical support to ensure students succeed in their studies.",
  },
];

function FItems() {
  const [activeIndex, setActiveIndex] = useState(null);
  const form = useRef();

  const handleToggle = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  // Handle form submission, send email, and show toast notification
  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Send email using Email.js
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID2,
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          toast.success("Message sent successfully!"); // Success message
          form.current.reset(); // Reset form after submission
        },
        (error) => {
          toast.error("Failed to send message. Try again."); // Error message
        }
      );
  };

  return (
    <>
      <section className="faq-area pt-120 pb-120 p-relative fix">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            {/* FAQ Section */}
            <div className="col-lg-7 col-md-12">
              <div className="section-title">
                <h2 className="faq-title">Frequently Asked Questions</h2>
                <p
                  className="faq-description"
                  style={{
                    fontSize: "18px",
                    textAlign: "left",
                  }}
                >
                  Have questions about Get knowledge Academy? Find answers to
                  the most common inquiries below or contact us directly for
                  more details.
                </p>
              </div>
              <div className="faq-wrap mt-30 pr-30">
                <div className="accordion" id="accordionExample">
                  {faqItems.map((item, index) => (
                    <div className="card" key={index}>
                      <div className="card-header" id={`heading${index}`}>
                        <h2 className="mb-0">
                          <button
                            className={`faq-btn${
                              activeIndex === index ? "" : " collapsed"
                            }`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${index}`}
                            onClick={() => handleToggle(index)}
                          >
                            {item.question}
                          </button>
                        </h2>
                      </div>
                      <div
                        id={`collapse${index}`}
                        className={`collapse${
                          activeIndex === index ? " show" : ""
                        }`}
                        data-bs-parent="#accordionExample"
                      >
                        <div className="card-body">{item.answer}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Contact Form Section */}
            <div className="col-lg-5 col-md-12">
              <div className="contact-bg02">
                <div className="section-title">
                  <h2 className="contact-title">
                    Contact Get knowledge Academy
                  </h2>
                </div>
                <form
                  ref={form}
                  onSubmit={handleFormSubmit} // Handles form submission without redirect
                  className="contact-form mt-30"
                >
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="contact-field p-relative c-name mb-20">
                        <input
                          type="text"
                          id="firstn"
                          name="from_name" // Match the Email.js template variable
                          placeholder="First Name"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="contact-field p-relative c-subject mb-20">
                        <input
                          type="email"
                          id="email"
                          name="from_email" // Match the Email.js template variable
                          placeholder="Email"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="contact-field p-relative c-subject mb-20">
                        <input
                          type="tel"
                          id="phone"
                          name="phone" // Match the Email.js template variable
                          placeholder="Phone No."
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="contact-field p-relative c-message mb-30">
                        <textarea
                          name="message" // Match the Email.js template variable
                          id="message"
                          cols={30}
                          rows={10}
                          placeholder="Write your message"
                          required
                        />
                      </div>
                      <div className="slider-btn">
                        <button
                          className="btn ss-btn"
                          type="submit"
                          style={{ width: "100%" }}
                        >
                          <span>Submit Now</span>{" "}
                          <i className="fal fa-long-arrow-right" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ToastContainer for notifications */}
      <ToastContainer position="top-center" autoClose={1000} />
    </>
  );
}

export default FItems;
