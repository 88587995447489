import React from "react";
import { Link } from "react-router-dom";

function Touch() {
  return (
    <>
      <section id="services" className="services-area pt-120 pb-90 fix">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title text-center mb-50 wow fadeInDown animated"
                data-animation="fadeInDown"
                data-delay=".4s"
              >
                <h5>
                  <i className="fal fa-graduation-cap" /> Contact Info
                </h5>
                <h2>Get In Touch</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-4">
              <div className="services-box text-center">
                <div className="services-icon">
                  <img src="assets/img/bg/contact-icon01.png" alt=" " />
                </div>
                <div className="services-content2">
                  <h5 style={{ fontSize: "1rem" }}>
                    <Link to="tel:+15874443800"> +1 (587) 444 3800</Link>
                  </h5>
                  <p>Phone Support</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="services-box text-center active">
                <div className="services-icon">
                  <img src="assets/img/bg/contact-icon02.png" alt="" />
                </div>
                <div className="services-content2">
                  <h5 style={{ fontSize: "1rem" }}>
                    <Link to="mailto:info@example.com">
                      info@evergreentrainingcentre.ca
                    </Link>
                  </h5>
                  <p>Email Address</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="services-box text-center">
                <div className="services-icon">
                  <img src="assets/img/bg/contact-icon03.png" alt=" " />
                </div>
                <div className="services-content2">
                  <h5 style={{ fontSize: "1rem" }}>
                    {" "}
                    10960 42 St NE Unit 240, Calgary, AB T3N 2B8
                  </h5>
                  <p>Office Address</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Touch;
