import React from "react";
import img from "../../assets/img/bg/IMG4.jpg"; // Replace with the correct path to the image
import {
  FaWalking,
  FaHandsHelping,
  FaShieldAlt,
  FaBriefcase,
} from "react-icons/fa"; // Import icons

const features = [
  {
    icon: <FaHandsHelping size={40} color="#6d8b3f" />,
    title: "Peer Tutoring",
    description:
      "The Peer Tutoring Program is free, one-on-one academic support available to Get knowledge Academy students. Students with higher grades are matched with those who require additional help in subjects. Through tutoring, students are able to boost their academic goals through a better understanding of course material.",
  },
  {
    icon: <FaWalking size={40} color="#6d8b3f" />,
    title: "Off Campus Activities",
    description:
      "At Get knowledge Academy, we believe that learning extends beyond the classroom. Off-campus activities help students engage in the real world, allowing them to apply their knowledge in practical scenarios.",
  },
  {
    icon: <FaBriefcase size={40} color="#6d8b3f" />,
    title: "Career Services",
    description:
      "With many career services, Get knowledge Academy assists students with job search, resume building, and internship placements.",
  },
  {
    icon: <FaShieldAlt size={40} color="#6d8b3f" />,
    title: "Safety",
    description:
      "Get knowledge Academy adheres to strict health and safety policies, ensuring that all students are provided with a safe and supportive environment.",
  },
  {
    icon: <FaBriefcase size={40} color="#6d8b3f" />,
    title: "Working on Campus",
    description:
      "Part-time job opportunities are available for students on campus in areas like administration, marketing, and IT support.",
  },
];

function GetInvolved() {
  return (
    <>
      <section className="get-involved-area pt-60 pb-60">
        {/* Full-width image */}
        <div className="container px-0">
          <div className="row no-gutters">
            <div className="col-lg-12">
              <img src={img} alt="Get Involved" className="img-fluid" />
            </div>
          </div>
        </div>

        {/* Features */}
        <div className="container mt-5">
          <div className="row justify-content-center">
            {features.map((feature, index) => (
              <div className="col-lg-4 col-md-6 mb-4" key={index}>
                <div
                  className="feature-box p-4 d-flex flex-column justify-content-between"
                  style={{
                    minHeight: "300px", // Fixed minimum height for equal size cards
                    border: "1px solid #e0e0e0",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="text-center">
                    <div className="icon mb-3">{feature.icon}</div>
                    <h3
                      className="feature-title text-center"
                      style={{
                        color: "#6d8b3f",
                        fontWeight: "bold",
                        marginBottom: "10px",
                      }}
                    >
                      {feature.title}
                    </h3>
                    <p className="text-left">{feature.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <style jsx>{`
          .feature-box {
            height: 100%; /* Makes the card height fill the container */
          }

          @media (max-width: 768px) {
            .feature-title {
              font-size: 1.25rem; /* Reduce font size for smaller screens */
            }
          }

          @media (max-width: 576px) {
            .feature-title {
              font-size: 1rem; /* Further reduce font size for very small screens */
            }
          }
        `}</style>
      </section>
    </>
  );
}

export default GetInvolved;
