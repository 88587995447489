import React from "react";
import Bredcom from "../Bredcom/Main";
import Header from "../headerone/Main";
import First from "../mencourses/First";

function Main() {
  return (
    <>
      <Header />
      <Bredcom title="Home" subtitle="Programs" />
      <First />
    </>
  );
}

export default Main;
